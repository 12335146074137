import { Box } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet";

export default function Page({ title, children }) {
  return (
    <Box
      flexGrow={1}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        minHeight: "90vh",
      }}
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </Box>
  );
}
