// import { Icon } from "@iconify/react";
// import { AppBar, Drawer, IconButton, Toolbar, Typography } from "@mui/material";
// import React from "react";
// import { Outlet, useNavigate } from "react-router-dom";

// export default function DashboardLayout() {
//   const navigate = useNavigate();
//   const handleLogout = () => {
//     navigate("/logout");
//   };
//   return (
//     <div>
//       <AppBar position="static">
//         <Toolbar>
//           <Typography variant="h6" sx={{ flexGrow: 1 }}>
//             Eventegration Control Panel
//           </Typography>
//           <IconButton color="inherit" onClick={handleLogout}>
//             <Icon icon="majesticons:door-exit" />
//           </IconButton>
//         </Toolbar>
//       </AppBar>
//       <Drawer open={true}>hi</Drawer>
//       <Outlet />;
//     </div>
//   );
// }

import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { gql, useQuery } from "@apollo/client";

const drawerWidth = 240;
// padding: theme.spacing(0.5, 2, 4, 2),

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: 0,
    marginTop: theme.mixins.toolbar.minHeight,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function PersistentDrawerLeft() {
  const { data } = useQuery(EVENT);
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const handleLogout = () => {
    navigate("/logout");
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          {/* <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton> */}
          <Typography
            variant="h6"
            fontSize={18}
            noWrap
            component="div"
            sx={{ flexGrow: 1 }}
          >
            {data?.event?.name || "Eventegration System"}
          </Typography>
          <IconButton color="inherit" onClick={handleLogout}>
            <Icon icon="majesticons:door-exit" />
          </IconButton>
        </Toolbar>
      </AppBar>
      {false && (
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          // variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            <Typography sx={{ flexGrow: 1 }}>Menu</Typography>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            <ListItem disablePadding>
              <ListItemButton
                selected={pathname === "/dashboard"}
                onClick={() => navigate("/dashboard")}
              >
                <ListItemIcon>
                  <Icon icon="ci:list-checklist" width={24} height={24} />
                </ListItemIcon>
                <ListItemText primary={"My Scanned Visitors"} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                selected={pathname === "/dashboard/scanner"}
                onClick={() => navigate("/dashboard/scanner")}
              >
                <ListItemIcon>
                  <Icon icon="eos-icons:content-new" width={24} height={24} />
                </ListItemIcon>
                <ListItemText primary={"QR Scanner"} />
              </ListItemButton>
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem disablePadding>
              <ListItemButton onClick={handleLogout}>
                <ListItemIcon>
                  <Icon icon="majesticons:door-exit" width={24} height={24} />
                </ListItemIcon>
                <ListItemText primary={"Logout"} />
              </ListItemButton>
            </ListItem>
          </List>
        </Drawer>
      )}
      <Main open={open}>
        {/* <DrawerHeader /> */}
        <Outlet />
      </Main>
    </Box>
  );
}

const EVENT = gql`
  query {
    event {
      name
    }
  }
`;
