import React from "react";
import { gql, useQuery } from "@apollo/client";
import { Launch, QrCodeScanner, Refresh } from "@mui/icons-material";
import { Box, Button, IconButton, Pagination, Typography } from "@mui/material";
import {
  DataGrid,
  GridToolbar,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import Page from "./Page";

export default function MyVisitorsPage() {
  const navigate = useNavigate();
  return (
    <Page title="My Scanned Visitors">
      <Typography variant="h4" gutterBottom>
        My Scanned Visitors
      </Typography>

      <MyVisitorsTable />

      <Button
        sx={{ mt: 2 }}
        size="large"
        variant="outlined"
        startIcon={<Launch />}
        endIcon={<QrCodeScanner />}
        onClick={() => navigate("scanner")}
      >
        Open Visitor Badge Scanner
      </Button>
    </Page>
  );
}

function CustomFooter({ refetch }) {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Box
      sx={{
        width: "100%",
        p: 1,
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <IconButton onClick={() => refetch()} title="Refresh">
        <Refresh />
      </IconButton>
      <Pagination
        color="primary"
        count={pageCount}
        page={page + 1}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    </Box>
  );
}

function MyVisitorsTable() {
  const limit = 100;
  const { data, error, refetch } = useQuery(GET_DATA, {
    variables: {
      conditions: { limit },
    },
  });
  const [columns, setColumns] = React.useState([]);

  React.useEffect(() => {
    if (!data?.visitors?.length) {
      return;
    }
    const keys = Object.keys(JSON.parse(data.visitors[0].data));

    setColumns(keys.map((k) => ({ field: k, width: 280 })));
  }, [data]);

  return (
    <React.Fragment>
      <DataGrid
        sx={{ height: 550 }}
        components={{
          Toolbar: GridToolbar,
          Footer: CustomFooter,
        }}
        componentsProps={{
          footer: { refetch },
        }}
        columnVisibilityModel={{
          id: false,
        }}
        columns={[{ field: "id" }, ...columns]}
        rows={
          data?.visitors?.map((v) => ({ id: v.id, ...JSON.parse(v.data) })) ||
          []
        }
        paginationMode="server"
        pageSize={limit}
        rowsPerPageOptions={[limit]}
        onPageChange={(p) =>
          refetch({
            conditions: {
              offset: p * limit,
              limit,
            },
          })
        }
        error={error}
        rowCount={data?.total || 0}
      />
    </React.Fragment>
  );
}

const GET_DATA = gql`
  query ($conditions: InputConditions) {
    visitors(conditions: $conditions) {
      id
      data
    }
    total: visitorsCount(conditions: $conditions)
  }
`;
