import React from "react";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Container,
  LinearProgress,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { gql, useMutation, useQuery } from "@apollo/client";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import logo from '../Assets/eventegration.png'
import { IMAGES_API_URL } from "../constants";
import bgImg from "../Assets/bgImg.jpg"

export default function LoginPage() {
  const [login, { loading, error }] = useMutation(gql`
    mutation ($eventId: String!, $email: String!, $password: String!) {
      token: login(eventId: $eventId, email: $email, password: $password)
    }
  `);

  const navigate = useNavigate();

  const onLogin = async (variables) => {
    return login({ variables }).then((resp) => {
      localStorage.setItem("auth.footfall.eventegration.com", resp.data.token);
      localStorage.setItem("eventId", variables.eventId);
      navigate("/dashboard");
    });
  };

  return (
    <Box
      sx={{
        minWidth: '100%',
        minHeight: '100vh',
        // backgroundColor: '#00509b',
        backgroundColor: '#121212',
        backgroundImage: `url(${bgImg})`,
        backgroundPosition: "fixed",
        backgroundAttachment: "fixed",
        backgroundSize: "cover",
        display: 'flex',
        flexDirection: "column",
        justifyContent: 'flex-start',
        alignItems: 'center',
        pt: 10
      }}
    >
      <Typography align="center" variant="h3" gutterBottom sx={{ color: '#fff', textShadow: '0px 2px 4px rgba(255, 255, 255, 0.3)' }}>
        Badge Scanning System
      </Typography>
      <Container maxWidth="sm">
        <Paper
          sx={{
            p: 3,
            mt: 4,
            borderRadius: 7,
            backgroundColor: 'rgba(255, 255, 255, 0.4)',
            boxShadow: '0px 2px 4px rgba(255, 255, 255, 0.1)',
          }}
          variant="outlined"
        >
          <Typography align="center" variant="h5" gutterBottom sx={{ color: 'white', my: 2 }}>

            Gate Scanner Access
          </Typography>
          <LoginForm onSubmit={onLogin} />

          {loading && <LinearProgress sx={{ mt: 2 }} />}

          {!!error && (
            <motion.div
              animate={{
                x: [30, -30, 15, -15, 8, 0],
              }}
              transition={{ duration: 0.4 }}
            >
              <Typography color="error" align="center" sx={{ mt: 2 }}>
                {error.message}
              </Typography>
              {/* <pre>{JSON.stringify(error, null, 1)}</pre> */}
            </motion.div>
          )}
        </Paper>
      </Container>
    </Box>
  );
}

export function LoginForm({ onSubmit }) {
  const { data, loading, error } = useQuery(gql`
  query  {
    eventList: getEventList{
      id
      name
    }
  }
`);
  const { handleSubmit, control, formState } = useForm({
    defaultValues: {
      eventId: "",
      email: "",
      password: "",
    },
  });
  if (loading) return <LinearProgress />
  if (error) return <Typography>{error.message}</Typography>
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        {/* <Controller
          name="eventId"
          control={control}
          rules={{
            required: 'Required',
          }}
          render={({ field }) => (
            <Autocomplete
              {...field}
              value={data.eventList.find((event) => event.id === field.value) || null}
              options={data.eventList || []}
              getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
              onChange={(_, newValue) => field.onChange(newValue?.id)}
              isOptionEqualToValue={(option, value) => option.id === value}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Event ID"
                  required
                  fullWidth
                  variant="filled"
                  sx={{
                    background: "#f4f4f4",
                    borderRadius: 2,
                    textAlign: "left",
                  }}
                  InputProps={{
                    ...params.InputProps,
                    style: { color: 'black' },
                    startAdornment: field.value && (
                      <img
                        src={`${IMAGES_API_URL}/logo/${field.value}.png`}
                        alt="Event Logo"
                        width="80px"
                        height="80px"
                        style={{ objectFit: "contain" }}
                      // onError={(e) => {
                      //   e.target.onerror = null; 
                      //   e.target.src = {logo}; 
                      // }}
                      />
                    ),
                  }}
                  InputLabelProps={{ style: { color: 'black' } }}
                />
              )}
              renderOption={(props, option) => (
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  spacing={2}
                  {...props}
                >
                  <img
                    src={`${IMAGES_API_URL}/logo/${option.id}.png`}
                    alt="Event Logo"
                    width="80px"
                    height="80px"
                    style={{ objectFit: "contain" }}
                  // onError={(e) => {
                  //   e.target.onerror = null; 
                  //   e.target.src = {logo}; 
                  // }}
                  />
                  <Typography variant="h6" flexWrap={"wrap"}>{option.name} <Typography variant="body1" fontSize={12} color="textSecondary" flexWrap={"wrap"}>{`(${option.id})`}</Typography></Typography>
                </Stack>
              )}
            />
          )}
        /> */}
        <Controller
          control={control}
          name="eventId"
          rules={{
            maxLength: 20,
            required: 'Required'
          }}
          render={({ field: { ref, ...field }, fieldState: { error } }) => (
            <TextField {...field} inputRef={ref} helperText={error?.message} variant="filled" 
            sx={{
              background: "#f4f4f4",
              borderRadius: 2,
              "& .MuiFilledInput-input:-webkit-autofill": {
                boxShadow: '0 0 0 100px #f4f4f4 inset',
                textFillColor: '#000',
                caretColor: '#000000',
                borderRadius: 2
              }
            }}
              InputProps={{ style: { color: 'black' } }}
              InputLabelProps={{ style: { color: 'black' } }} error={!!error} color="success" label="Event Id" />
          )}
        />
        <Controller
          control={control}
          name="email"
          rules={{
            required: "Required",
          }}
          render={({ field: { ref, ...field }, fieldState: { error } }) => (
            <TextField
              {...field}
              inputRef={ref}
              helperText={error?.message}
              error={!!error}
              label="Email/UserId"
              required
              fullWidth
              variant="filled"
              autoComplete="new-password"
              sx={{
                background: "#f4f4f4",
                borderRadius: 2,
                "& .MuiFilledInput-input:-webkit-autofill": {
                  boxShadow: '0 0 0 100px #f4f4f4 inset',
                  textFillColor: '#000',
                  caretColor: '#000000',
                  borderRadius: 2
                }
              }}
              InputProps={{ style: { color: 'black' } }}
              InputLabelProps={{ style: { color: 'black' } }}
            />
          )}
        />
        <Controller
          control={control}
          name="password"
          rules={{
            required: "Required",
          }}
          render={({ field: { ref, ...field }, fieldState: { error } }) => (
            <TextField
              {...field}
              inputRef={ref}
              helperText={error?.message}
              error={!!error}
              label="Password"
              required
              fullWidth
              variant="filled"
              type="password"
              sx={{
                background: "#f4f4f4",
                borderRadius: 2,
                "& .MuiFilledInput-input:-webkit-autofill": {
                  boxShadow: '0 0 0 100px #f4f4f4 inset',
                  textFillColor: '#000',
                  caretColor: '#000000',
                  borderRadius: 2
                }
              }}
              InputProps={{ style: { color: 'black' } }}
              InputLabelProps={{ style: { color: 'black' } }}
            />
          )}
        />

        <LoadingButton
          size="large"
          variant="contained"
          type="submit"
          loading={formState.isSubmitting}
          sx={{
            background: "rgba(252, 196, 1, 0.8)",
            "&:hover": {
              background: "rgba(252, 196, 1, 1)",
              "& .MuiButton-contained": {
                backgroundColor: "rgba(252, 196, 1, 1)"
              }
            }
          }}
        >
          Enter
        </LoadingButton>
      </Stack>
      <Stack mt={6} direction={"column"} justifyContent={"end"} >
        <Typography align="center" color={"black"} fontSize={10}>
          Powered by...
        </Typography>
        <Stack direction={"row"} justifyContent={"center"} >
          <Box width="30%" sx={{ backgroundColor: "transparent", borderRadius: 1 }}>
            <img src={logo} width="95%" alt="Logo" />
          </Box> </Stack>
      </Stack>
    </form>
  );
}
